import Vue from "vue";

/*TITLE*/
document.title = "LAS LOMAS, Torremolinos | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "LAS LOMAS, Torremolinos";
Vue.prototype.$subtitle = "Vivendas de 1, 2 y 3 dormitorios con terraza, piscina y gimnasio";

/*INTRO*/
Vue.prototype.$promoter = "LAS LOMAS, Torremolinos";
Vue.prototype.$introSubtitle = "Vivendas de 1, 2 y 3 dormitorios con terraza, piscina y gimnasio";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-b_-linkflooor-kingdom-greece-bison-encimera-xtone-solo-white-00000--20230113050100.jpg";
Vue.prototype.$image_kitchen = "cocina-d_-greece-bison-encimera-xtone-solo-white-elect.-balay-00000--20230113050113.jpg";
Vue.prototype.$image_bath1 = "banyo-1-d_-greece-bottega-acero-spiga-acero-mb-contract-blanco-lavabo-blanco-one-doble-seno-120cm-espejo-120cm--20230113050109.jpg";
Vue.prototype.$image_bath2 = "banyo-2-b_-greece-oxo-line-china-blanco-mb-contract-blanco-lavabo-one-1-seno-60cm-espejo-60cm-00000--20230113050141.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-b_-linkflooor-kingdom-greece-bison-encimera-xtone-solo-white-00000--20230113050100.jpg",
  },
  {
    src: "salon-a_-linkfloor-persia-sal-brillo-encimera-caliza-00000--20230113050106.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-d_-greece-bison-encimera-xtone-solo-white-elect.-balay-00000--20230113050113.jpg",
  },
  {
    src: "cocina-c_-greece-blanco-nubol-encimera-antracita-elect.-balay-00000--20230113050121.jpg",
  },
  {
    src: "cocina-b_-persia-sal-brillo-encimera-acero-elect.-balay-00000--20230113050129.jpg",
  },
  {
    src: "cocina-a_-persia-sal-brillo-encimera-caliza-elect.-balay-00000--20230113050135.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-d_-greece-bottega-acero-spiga-acero-mb-contract-blanco-lavabo-blanco-one-doble-seno-120cm-espejo-120cm--20230113050109.jpg",
  },
  {
    src: "banyo-1-c_-greece-bottega-caliza-spiga-caliza-mb-contract-blanco-lavabo-blanco-one-doble-seno-120cm-espejo-120cm-00000--20230113050118.jpg",
  },
  {
    src: "banyo-1-b_-persia-bottega-acero-spiga-acero-mb-contract-blanco-lavabo-blanco-one-doble-seno-120cm-espejo-120cm-00000--20230113050126.jpg",
  },
  {
    src: "banyo-1-a_-persia-bottega-caliza-spiga-caliza-mb-contract-blanco-lavabo-blanco-one-doble-seno-120cm-espejo-120cm-00000--20230113050133.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-b_-greece-oxo-line-china-blanco-mb-contract-blanco-lavabo-one-1-seno-60cm-espejo-60cm-00000--20230113050141.jpg",
  },
  {
    src: "banyo-2-a_-persia-oxo-line-china-blanco-mb-contract-blanco-lavabo-one-1-seno-60cm-espejo-60cm--20230113050148.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LAS_LOMAS/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/1WyrHRP1xC0";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20lomas%20de%20los%20riscos%2072%20torremolinos",
    text: "Calle Lomas de Los Riscos 72 - Torremolinos",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=plaza%20costa%20del%20sol%2015%201ºizq%20torremolinos",
    text: "Plaza Costa del Sol 15,  1ºIzq. Torremolinos",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:952000456",
    text: "952000456",
  },
  {
    icon: "mdi-email",
    link: "mailto:laslomas@primehouse.es",
    text: "laslomas@primehouse.es",
  },
];
